.carousel {
  position: relative;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  font-family: 'Montserrat', sans-serif;
  color: #e6007e;
}

.slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
}

.stat-item {
  background-color: white;
  color: #e6007e;
  padding: 20px;
  border: 2px solid #e6007e;
  border-radius: 15px;
  margin-bottom: 20px;
  width: 80%;
}

.title-number {
  font-size: 15px;
}

.stat-item h3 {
  font-size: 25px;
  font-weight: bolder;
  margin: 0;
}

.stat-item .description {
  font-size: 18px;
  margin-top: 10px;
}

.btn-next {
  padding: 10px 20px;
  background-color: #e6007e;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.btn-next:hover {
  background-color: #ffffff;
  color: #e6007e;
  border: 2px solid #e6007e;
}

.btn-completar-formulario-home {
  padding: 10px 20px;
  background-color: #EE4296;
  font-size: 20px;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
}
@media (max-width: 600px) {
  .carousel {
    padding: 15px;
  }

  .stat-item {
    width: 90%;
    padding: 15px;
  }

  .stat-item h3 {
    font-size: 20px;
  }

  .stat-item .description {
    font-size: 16px;
  }

  .btn-completar-formulario-home {
    font-size: 18px;
    padding: 2vh 2vw;
    border-radius: 0.5vh;
  }
}

.statistics {
  display: flex;
  flex-direction: row; /* Alinea las estadísticas verticalmente */
  justify-content: center;
  align-items: flex-start; /* Alinea los cuadros al lado izquierdo */
  padding: 30px;
  font-family: 'Montserrat', sans-serif;
  color: #e6007e; /* Cambia el color de las letras al color rosado */
  gap: 2vh; /* Espacio entre los cuadros */
  margin-top: 3vh;
}
.title-number{
  font-size: 15px;
}

/* .stat-item {
  width: 80%; /* Hace que el ancho ocupe toda la columna */
  /*background-color: white; /* Cambia el fondo a blanco */
  /*color: #e6007e; /* Cambia el color del texto a rosado */
  /*padding: 35px;
  border: 2px solid #e6007e; /* Agrega un borde rosado */
  /*border-radius: 15px;
  margin-bottom: 20px;
  margin-left: 5vw;
  transition: background-color 0.3s ease, transform 0.3s ease;
  position: relative; /* Para posicionar la descripción */
/*} */

 .stat-item {
  width: 80%; /* Hace que el ancho ocupe toda la columna */
  background-color: white; /* Cambia el fondo a blanco */
  color: #e6007e; /* Cambia el color del texto a rosado */
  padding: 35px;
  border: 2px solid #e6007e; /* Agrega un borde rosado */
  border-radius: 15px;
  margin-bottom: 20px;
  margin-left: 5vw;
  transition: background-color 0.3s ease, transform 0.3s ease;
  position: relative; /* Para posicionar la descripción */
} 

.stat-item h3 {
  font-size: 25px; /* Ajusta el tamaño del título */
  font-weight: bolder;
  margin: 0;
}

.stat-item .description {
  color: #e6007e; /* Cambia el color de la descripción a rosado */
  font-size: 18px;
  margin-top: 10px;
  opacity: 0; /* Inicialmente oculta la descripción */
  height: 0;
  overflow: hidden;
  transition: opacity 0.3s ease, height 0.3s ease; /* Transiciones suaves */
}

.stat-item:hover .description {
  opacity: 1; /* Muestra la descripción al hacer hover */
  height: auto; /* Deja que la altura se ajuste al contenido */
}

.stat-item:hover {
  transform: scale(1.05); /* Aumenta ligeramente el tamaño del cuadro */
}
.btn-completar-formulario-home {
  padding: 3vh 3vw;
  background-color: #EE4296;
  font-size: 20px;
  color: #ffffff;
  border: none;
  border-radius: 1vh;
  margin-top: 1vh;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  align-self: center; /* Alinea el botón al principio */
  
}
.btn-completar-formulario-home:hover {
  background-color: #ffffff; /* Cambia el fondo a blanco */
  color: #e6007e; /* Cambia el color de las letras a rosado */
  border: 2px solid #e6007e;
}

/* version celular */
@media (max-width: 600px) {
  .statistics {
    padding: 15px;
    gap: 1.5vh;
    margin-top: 2vh;
    flex-direction: column;
  }

  .stat-item {
    width: 90%; /* Ocupa más ancho en pantallas pequeñas */
    padding: 20px;
    margin-left: 0;
  }

  .stat-item h3 {
    font-size: 20px; /* Tamaño de fuente más pequeño */
  }

  .stat-item .description {
    font-size: 16px; /* Tamaño de fuente más pequeño */
  }

  .btn-completar-formulario-home {
    font-size: 18px; /* Reduce el tamaño de la fuente del botón */
    padding: 2vh 2vw; /* Ajusta el padding del botón */
    border-radius: 0.5vh;
  }
}