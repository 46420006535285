.carousel {
  position: relative;
  max-width: 100%;
  height: 45vh;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 10px;
}

.slide_carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40vh;
  width: 100%;
  position: relative;
}

.image_carousel {
  width: 100vw;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.5s ease-in-out;
  filter: blur(12px);
}

.overlay_carousel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #41363c95; /* Cuadrado de color con opacidad */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.overlay-text_carousel {
  width: 80vw;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 25px;
  font-weight: bolder;
  text-align: center;
  line-height: 1.5;
  letter-spacing: 2px;
  
}
 .btn-siguiente-home {
    padding: 3vh 3vw;
    background-color: #EE4296;
    font-size: 20px;
    color: #ffffff;
    border: none;
    border-radius: 1vh;
    margin-top: 1vh;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    cursor: pointer;
    align-self: center; /* Alinea el botón al principio */
    
  }
  .btn-siguiente-home:hover {
    background-color: #ffffff; /* Cambia el fondo a blanco */
    color: #e6007e; /* Cambia el color de las letras a rosado */
    border: 2px solid #e6007e;}

    .overlay-title_carousel {
      margin-left: 2vw;
      font-size: 25px;
      font-weight: bolder;
      color: white;
      text-align: left;
      font-family: 'Montserrat', sans-serif;
      margin-bottom: 10px;
    }
    
    .overlay-description_carousel {
      width: 60vw;
      color: white;
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      text-align: left;
      line-height: 1.5;
      letter-spacing: 1px;
      margin-bottom: 20px;
      margin-left: 1vw;
      margin-right: 1vw;
    }
    .overlay-title-number_carousel{
      font-size: 90px;
    }


@media (max-width: 860px) {
  .carousel {
    height: 60vh; /* Reducir la altura del carrusel */
  }

  .slide_carousel {
    height: 60vh; /* Mantener altura consistente con el carrusel */
  }

  .image_carousel {
    width: 100vw;
    height: 100%;
    object-fit: cover;
    filter: blur(10px); /* Reducir el nivel de desenfoque en móvil */
  }

  .overlay_carousel {
    padding: 15px; /* Reducir padding en el overlay */
    flex-direction: column;
  }

  .overlay-text_carousel {
    width: 90vw;
    font-size: 18px; /* Tamaño de fuente más pequeño */
    line-height: 1.3; /* Ajustar el interlineado */
    letter-spacing: 1px;
  }
  .btn-siguiente-home {
    padding: 3vh 3vw;
    background-color: #EE4296;
    font-size: 16px;
    color: #ffffff;
    border: none;
    border-radius: 1vh;
    margin-top: 1vh;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    cursor: pointer;
    align-self: center; /* Alinea el botón al principio */
    position: relative;
    z-index: 1000;
    
  }
  .btn-siguiente-home:hover {
    background-color: #ffffff; /* Cambia el fondo a blanco */
    color: #e6007e; /* Cambia el color de las letras a rosado */
    border: 2px solid #e6007e;
  }
  .overlay-title_carousel {
    margin-left: 2vw;
    font-size: 14px;
    font-weight: bolder;
    color: white;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 10px;
  }
  
  .overlay-description_carousel {
    width: 80vw;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    text-align: left;
    line-height: 1.5;
    letter-spacing: 1px;
    margin-bottom: 20px;
    margin-left: 1vw;
    margin-right: 1vw;
  }
  .overlay-title-number_carousel{
    font-size: 30px;
    color: #ffffff;
  }
}
