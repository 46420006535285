.divider {
    width: 80%; /* Ajusta el ancho de la línea */
    margin: 50px auto; /* Centra la línea horizontalmente con margen superior e inferior */
    border: none;
    border-top: 4px solid #ff69b4; /* Cambia el color y grosor de la línea */
    margin-top: 8px;
    margin-bottom: 8px;
  }
.bienvenida{
  height: 6vh;
  width: 100vw;
  left: 50;
  padding: 4px;
  color: #515050;
  font-family: 'Montserrat', sans-serif;
  font-size: 35px;
  font-weight: lighter;
  align-items: center;
  text-align: center;
  line-height: 1.5;
  letter-spacing: 0.2vw;
  margin-top: 2vh;
}
.bienvenida-explicacion{
  height: 6vh;
  width: 100vw;
  left: 50;
  padding: 4px;
  color: #515050;
  font-family: 'Montserrat', sans-serif;
  font-size: 5px;
  font-weight: lighter;
  align-items: center;
  text-align: center;
  line-height: 1.5;
  letter-spacing: 0.2vw;
  margin-top: 4vh;
}
.logo-wot {
  width: 350px;  /* Ajusta el tamaño según sea necesario */
  height: auto;
  vertical-align: middle;  /* Para que el logo esté alineado con el texto */
}
.description-home{
  font-family: 'Montserrat', sans-serif; /* Usar fuente Montserrat */
  font-size: 15px; /* Tamaño de fuente más grande */
  color: #888888; /* Color gris */
  margin-top: 6vh;
  margin-left: 4vw; /* Margen en el lado izquierdo */
  margin-bottom: 20px; /* Espacio debajo */
  text-align: left; /* Alinear el texto a la izquierda */
  letter-spacing: 0.1vw;

}

@media (max-width: 600px) {
  .divider{
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
  .bienvenida {
    height: auto;
    font-size: 24px; /* Tamaño de fuente más pequeño */
    padding: 8px;
    margin-top: 3vh; /* Margen superior menor */
    height: 3vh;
    margin-bottom: 8vh;
  }
  .bienvenida-explicacion {
    height: auto;
    font-size: 14px; /* Tamaño de fuente más pequeño */
    padding: 8px;
    margin-top: 1vh; /* Margen superior menor */
    height: 3vh;
  }

  .logo-wot {
    width: 200px; /* Reducir el tamaño del logo */
    height: auto;
  }

  .description-home {
    font-size: 10px; /* Tamaño de fuente más pequeño */
    margin-left: 2vw; /* Menor margen en el lado izquierdo */
    margin-top: 4vh; /* Reducir margen superior */
    text-align: center; /* Centramos el texto en pantallas móviles */
  }
}
