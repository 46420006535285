.form-group-editar {
    font-family: "Montserrat", sans-serif;
    position: relative;
    text-align: left;
    flex-basis: 45%;
    margin-top: 10%;

}

.br-mobile{
  display: none;
}

.form-control-editar {
    font-family: "Montserrat", sans-serif;
    width: 80%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5%;
    position: relative;
}

.form-control-no-respondido-editar {
    font-family: "Montserrat", sans-serif;
    border-color: #e6007e;
}

.label-negrita-editar {
  font-family: "Montserrat", sans-serif;
  position:relative;
  font-weight: bold;
  font-size: 1vw;
  text-align: left;
  display: block; 
  margin-bottom: 0.5rem;
}

.label-gris-editar {
  font-family: "Montserrat", sans-serif;
  color: #777;
  font-size: 0.8vw;
  position: relative;
  text-align: left;
  width: 100%;
  display: block; 
  margin-bottom: 0.5rem;
}

.label-roja-editar {
    font-family: "Montserrat", sans-serif;
    color: #ff0000;
}

.botones-editar {
    display: flex;
    justify-content: space-between;
}
.container-editarContraseña {
    width: 100%;
    max-width: 450px;
    min-width: 300px;
    min-height: 65vh;
    padding: 3vh 2vw;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 3.5rem auto; /* Centrado horizontal y separación arriba */
    transform: none;
    z-index: 10;
}




/* Estilos para los botones */
.button-container-editarcontrasena {
  display: flex;
  justify-content: space-between;
  width: 16vw;
  margin-top: 2vh; /* Espaciado superior */
}
.editar-container {
  position: relative; /* Necesario para que el contenedor hijo se centre correctamente */
  height: 100vh; /* Para que ocupe toda la altura de la pantalla */
  width: 100vw; /* Ocupa todo el ancho de la pantalla */
}



/* Asegura que el fondo se ajuste responsivamente */
.image-city-editar {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1; 
}
  
.image-city-edit {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }


.fondo_rosado_editar{
    background-color: #e6007e; /* Rosado con opacidad */
    opacity: 0.09;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

.fondo_rosado_editarContraseña {
    background-color: #e6007e; 
    opacity: 0.09;
    object-fit: cover;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2;

  }

  .container-editar {
    width: 100%;
    max-width: 450px;
    min-width: 300px;
    min-height: 85vh;
    padding: 3vh 2vw;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 3.5rem auto; 
    transform: none;
    z-index: 10;
  }
  .question {
    display: flex;
    height: 50vh;
    width: 80vw;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    position: absolute;
    z-index: 5; 
    background-color: transparent;
    margin-top: 2%;
    margin-left: 5%;
    border-radius: 10px;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 5rem;
}


  .fondo_gris_editar {
    background-color: #5A5A5A; 
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .fondo_blanco_editar {
    background-color: #ffffff; 
    opacity: 0.4;
    position: absolute;
    top: 25%;
    left: 0;
    width: 100%;
    height: 55%;
    z-index: 3;
  }
  .fondo_blanco_editarContraseña {
    background-color: #ffffff; 
    opacity: 0.4;
    position: absolute;
    top: auto; 
    left: 50vw; 
    transform: translate(-50%, 30%); 
    width: 100vw; 
    height: auto; 
    min-height: 50vh; 
    z-index: 3;
    border-radius: 8px; 
}


.nueva-clase-editar {
display: flex;
align-items: center;
}

.input-multi-select-editar {
    font-family: 'Montserrat';
    border-radius: 5px;
    width: 25vw;
    border: 1px solid #D9D9D9;
    margin-top: 2vw;   
    font-size: 1vw;
}

.form-control-brief-editar {
    width: 30vw;
    height: 13.8vh;
    border-radius: 7px;
    border: 1px solid #D9D9D9;
    margin-bottom: 1vh;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5vh;
    line-height: 1.22;
    font-weight: 500;
    resize: none;  
}
.form-control-brief-no-respondido-editar {
    width: 54vw;
    height: 13.8vh;
    border-radius: 7px;
    border: 1px solid #fc047c;
    margin-bottom: 1vh;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5vh;
    line-height: 1.22;
    font-weight: 500;
    resize: none;  
}

.message-forgot-password {
    margin-bottom: 10px; 
}

.instructions-forgot-password {
    margin-bottom: 15px; 
}
.button-container-editar {
  position: absolute;
  bottom: 1vh; 
  left: 50%; 
  transform: translateX(-50%); 
  width: 100%;
  display: flex;
  justify-content: center; 
  padding: 1rem; 
  background-color: transparent;
  z-index: 999; 
  gap: 9rem;
}


@media (max-width: 768px) {
.form-group-editar {
      flex-basis: 100%;
      margin-top: 2rem;
      
  }
.label-negrita-editar{
  font-size: 0.85rem;
}
.label-gris-editar{
  font-size: 0.7rem;
}
 .fondo_blanco_editar{
  display: none;
 }
 .button-container {
  flex-direction: column; /* Cambia a columna en pantallas móviles */
  align-items: center;
  margin-top: 3rem; /* Más espacio en la parte superior */
}

.button-container button {
  width: 80%;
  margin-bottom: 1.5rem; /* Espacio entre botones */
}
  
.container-editarContraseña{
  margin-left: 1.4rem;
  width: 80%;
  height: 90%;
  position:relative;
  margin-top: 3rem;
}
.container-editar {
  margin-left: 1.6rem;
  width: 80%;
  height: 80vh;
  position:relative;
  margin-top: 3rem;
}

.fondo_rosado_editar {
    background-color: #e6007e; /* Rosado con opacidad */
    opacity: 0.09;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2;
    object-fit: cover;
  }

  .form-control-brief-editar, .form-control-brief-no-respondido-editar {
      width: 8rem;
      height: 3rem;
  }
  .form-control-editar{
    width: 8rem;
    height: 3rem;
    margin-top: 1rem;
  }

  .input-multi-select-editar {
      width: 80%;
      font-size: 4vw;
  }
  .image-city-editar {
    width: 100vw;
    height: 100vh; 
    object-fit: cover;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .image-city-edit {
    width: 100vw;
    height: 100vh; 
    object-fit: cover;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .br-mobile{
    display: block;
  }
  .button-container-editar {
    flex-direction: column; /* En pantallas pequeñas, los botones se apilarán */
    align-items: center;
    gap: 5rem;
    margin-top: 15vh;
    margin-left: -1vh;
  }

  .button-container-editar button {
    margin: 1px 0; /* Espaciado entre los botones en pantallas pequeñas */
  }
  .button-container-editarcontrasena {
    flex-direction: column; /* En pantallas pequeñas, los botones se apilarán */
    align-items: center;
    gap: 5rem;
    margin-top: 10vh;
    margin-left: -1vh;
  }

  .button-container-editarcontrasena button {
    margin: 1px 0; /* Espaciado entre los botones en pantallas pequeñas */
  }

}
