.logos-section {
    display: flex;
    justify-content: center; /* Centra los logos horizontalmente */
    align-items: center;
    flex-wrap: wrap; /* Permite que los logos se ajusten en múltiples líneas si es necesario */
    gap: 20px; /* Espacio entre los logos */
    padding: 20px;
  }
  
  .logo_me {
    width: 200px; /* Ancho fijo para los logos */
    height: auto; /* Mantiene la proporción de los logos */
    transition: transform 0.3s ease-in-out; /* Transición para un efecto al hacer hover */
  }
  
  .logo:hover {
    transform: scale(1.1); /* Efecto de agrandamiento al hacer hover */
  }
  

  @media (max-width: 600px) {
    .logos-section {
      padding: 10px; /* Reducir padding en general */
      gap: 10px; /* Reducir espacio entre logos */
    }
  
    .logo_me {
      width: 150px; /* Ancho menor para los logos */
      height: auto;
    }
  }
  