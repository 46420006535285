/* .section-container {
    background-color: #ffffff;
    padding: 1.5vw;
    margin-bottom: 2vw; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 57vw;
    margin-left: 4vw;
  }
  
  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1vw;
  }
  
  .section-header h2 {
    font-size: 1.2vw;
    color: rgb(103, 103, 103);
    font-weight: bold;
  }
  
  .section-description {
    font-size: 1vw;
    color: rgb(103, 103, 103);
    text-align: justify;
    white-space: pre-line;
  }
  
  .section-actions {
    display: flex;
    gap: 0.5vw;
  }
  
  .btn-edit,
  .btn-delete {
    padding: 0.5vw 1vw;
    border: none;
    border-radius: 0.5vw;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
  }
  
  .btn-edit {
    background-color: #E6007E;
    color: #ffffff;
  }
  
  .btn-edit:hover {
    background-color: #d7006c;
  }
  
  .btn-delete {
    background-color: #f0f0f0;
    color: #e6007e;
    border: 1px solid #e6007e;
  }
  
  .btn-delete:hover {
    background-color: #e6007e;
    color: #ffffff;
  }


  @media (max-width: 1024px) {
    .section-container {
      width: 90vw; /* Ajusta el ancho para que ocupe casi toda la pantalla */
      /*padding: 3vw; /* Incrementa el padding para mayor espacio en pantallas pequeñas */
      /*margin-left: 2.5vw; /* Centra el contenido */
    /*}
  
    .section-header h2 {
      font-size: 3vw; /* Ajusta el tamaño de la fuente en pantallas más pequeñas */
     /*margin-top: 2vw;
      margin-bottom: 3vw;
    }
  
    .section-description {
      font-size: 3vw; /* Ajusta el tamaño del texto descriptivo */
       /*}
  
    .section-actions {
      flex-direction: column; /* Cambia la dirección de los botones para apilarlos */
      /*gap: 1vw; /* Incrementa el espacio entre los botones */
    /*}
  
    .btn-edit,
    .btn-delete {
      margin-top: 2vw;
      padding: 2vw 3vw; /* Ajusta el tamaño de los botones */
      /*font-size: 2.5vw; /* Aumenta el tamaño del texto en los botones */
      /*width: 100%; /* Los botones ocuparán todo el ancho disponible */
      
    /*}
  }
  
  /* Adaptación para pantallas muy pequeñas (móviles) */
  /*@media (max-width: 480px) {
    .section-container {
      padding: 0.3rem;
      width: 83vw;
      margin-left: 2rem;
    }
  
    .section-header h2 {
      font-size: 0.6rem;
    }
  
    .section-description {
      font-size: 0.4rem;

    }
  
    .section-actions {
      gap: 0.1rem; 
    }
  
    .btn-edit{
      font-size: 0.4rem;
      padding: 0.4rem 0.4rem;
    }
    .btn-delete {
      font-size: 0.4rem;
      padding: 0.4rem 0.4rem;
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    .section-container {
      width: 85%; /* Ajusta el ancho para pantallas intermedias usando rem */
      /*padding: 1rem; /* Ajusta el padding */
      /*margin-left: 1rem; /* Centra el contenido */
    /*}
  
    .section-header h2 {
      font-size: 1rem; /* Ajusta el tamaño de la fuente */
      /*margin-top: 1rem;
      margin-bottom: 1.5rem;
    }
  
    .section-description {
      font-size: 0.9rem; /* Ajusta el tamaño del texto descriptivo */
      /*line-height: 1.5rem; /* Aumenta la altura de línea para mejorar la legibilidad */
    /*}
  
    .section-actions {
      flex-direction: row; /* Mantiene los botones en fila */
      /*gap: 0.8rem; /* Ajusta el espacio entre los botones */
    /*}
  
    .btn-edit{
      padding: 0.8rem 1rem; /* Ajusta el tamaño de los botones */
     /* font-size: 1rem; /* Aumenta el tamaño del texto en los botones */
    /*}
    .btn-delete {
      padding: 0.8rem 1rem; /* Ajusta el tamaño de los botones */
      /*font-size: 1rem; /* Aumenta el tamaño del texto en los botones */
    /*}
  }
   */
   .section-container {
    background-color: #ffffff;
    padding: 1.5vw;
    margin-bottom: 2vw; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 57vw;
    margin-left: 4vw;
    margin-top: 5vh;
  }
  
  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1vw;
  }
  
  .section-header h2 {
    font-size: 1.2vw;
    color: rgb(103, 103, 103);
    font-weight: bold;
  }
  
  .section-description {
    font-size: 1.2vw;
    color: rgb(103, 103, 103);
    text-align: justify;
    white-space: pre-line;
  }
  
  .section-actions {
    display: flex;
    gap: 0.5vw;
  }
  
  .btn-edit,
  .btn-delete {
    padding: 0.5vw 1vw;
    border: none;
    border-radius: 0.5vw;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
  }
  
  .btn-edit {
    background-color: #E6007E;
    color: #ffffff;
  }
  
  .btn-edit:hover {
    background-color: #E6007E;
  }
  
  .btn-delete {
    background-color: #f0f0f0;
    color: #E6007E;
    border: 1px solid #E6007E;
  }
  
  .btn-delete:hover {
    background-color: #E6007E;
    color: #ffffff;
  }


  @media (max-width: 1024px) {
    .section-container {
      width: 90vw; /* Ajusta el ancho para que ocupe casi toda la pantalla */
      padding: 2rem; /* Incrementa el padding para mayor espacio en pantallas pequeñas */
      margin-left: 2.5vw; /* Centra el contenido */
    }
  
    .section-header h2 {
      font-size: 12px; /* Ajusta el tamaño de la fuente en pantallas más pequeñas */
      margin-top: 0.5rem;
    }
  
    .section-description {
      font-size: 1rem; /* Ajusta el tamaño del texto descriptivo */
      /* margin-top: 1rem; */
       }
  
    .section-actions {
      flex-direction: column; /* Cambia la dirección de los botones para apilarlos */
      gap: 2rem; /* Incrementa el espacio entre los botones */
    }
  
    .btn-edit,
    .btn-delete {
      margin-top: 1rem;
      padding: 0.5rem 1rem; /* Ajusta el tamaño de los botones */
      font-size: 1rem; /* Aumenta el tamaño del texto en los botones */
      width: 100%; /* Los botones ocuparán todo el ancho disponible */
      z-index: 100;
    }
  }
  
  /* Adaptación para pantallas muy pequeñas (móviles) */
  @media (max-width: 480px) {
    .section-container {
      padding: 0.6rem;
      width: 83vw;
      margin-left: 2rem;
    }
  
    .section-header h2 {
      font-size: 0.6rem;
    }
  
    .section-description {
      font-size: 9px;

    }
  
    .section-actions {
      gap: 0.1rem; 
    }
  
    .btn-edit{
      font-size: 0.4rem;
      padding: 0.4rem 0.4rem;
      z-index: 100;
    }
    .btn-delete {
      font-size: 0.4rem;
      padding: 0.4rem 0.4rem;
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    .section-container {
      width: 85%; /* Ajusta el ancho para pantallas intermedias usando rem */
      padding: 1rem; /* Ajusta el padding */
      margin-left: 1rem; /* Centra el contenido */
    }
  
    .section-header h2 {
      font-size: 1rem; /* Ajusta el tamaño de la fuente */
      margin-top: 1rem;
      margin-bottom: 1.5rem;
    }
  
    .section-description {
      font-size: 0.9rem; /* Ajusta el tamaño del texto descriptivo */
      line-height: 1.5rem; /* Aumenta la altura de línea para mejorar la legibilidad */
    }
  
    .section-actions {
      flex-direction: row; /* Mantiene los botones en fila */
      gap: 0.8rem; /* Ajusta el espacio entre los botones */
    }
  
    .btn-edit{
      padding: 0.8rem 1rem; /* Ajusta el tamaño de los botones */
      font-size: 1rem; /* Aumenta el tamaño del texto en los botones */
      z-index: 100;
    }
    .btn-delete {
      padding: 0.8rem 1rem; /* Ajusta el tamaño de los botones */
      font-size: 1rem; /* Aumenta el tamaño del texto en los botones */
    }
  }
  
   