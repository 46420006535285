.main-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.main-container-login{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50vw;
    height: 100vh;
}

.left-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    width: 50vw;
}

.right-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50vw;
    height: 100vh;
}

.logo-login {
    width: 20%; 
    max-width: 30vw; 
    height: auto; 
    margin-bottom: 20px;
}

.image-login {
    width: 50%; 
    max-width: 50vw;
    height: auto;
    max-height: 60vh;
    border: 0.5% solid black;
}

.message-login {
    font-size: 2em; /* Tamaño de fuente relativo al tamaño del contenedor */
    font-weight: 800;
    line-height: 1.47em; /* Línea de altura relativa */
    letter-spacing: 0; /* Sin espaciado entre letras */
    text-align: center;
    color: #5A5A5A;
}

.box-user-login{
    margin: 20px;
    font-family: 'Montserrat';
    font-weight: 800;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.textfield-user-login{
    border-radius: 7px;
    background-color: #F5F5F5;
}

.button-forgot-password{
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: #E6007E;
    text-decoration: none;
    align-self: flex-end;
}

.help{
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: #50B1C9;
    text-decoration: none;
    align-self: flex-end;
}

.message-forgot-password{
    font-family: 'Montserrat';
    font-size: 30px;
    font-weight: 800;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: center;
    color: #5A5A5A;
    margin-bottom: 0px;
}

.instructions-forgot-password{
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: #5A5A5A;
    margin-bottom: 30px;
    max-width: 100%;
    width: auto;
    height:auto
}

.terms-links {
    font-family: 'Montserrat';
    margin-top: 10px;  
}

.links-separator {
    margin: 0 10px;  
}

.terms-links a {
    font-family: 'Montserrat';
    color: rgb(252, 4, 124);
    text-decoration: underline; 
}
.checkbox-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centramos los elementos horizontalmente */
    margin-bottom: 10px;
}

.checkbox-label {
    font-family: 'Montserrat';
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.message-register {
    font-family: 'Montserrat';
    font-size: 30px;
    font-weight: 800;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: center;
    color: #5A5A5A;
}

.error-message {
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: #E6007E;
}

.enter-text {
    font-family: 'Montserrat';
    font-size: 17px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: #5A5A5A;
    margin-bottom: 30px;
}
@media only screen and (max-width: 840px) {
    .button-forgot-password{
        width: 80vw;
    }
    .box-user-login{
        font-family: 'Montserrat';
        width: 80%;
        margin-left: 2.5rem;
    }
    
    .main-container {
        flex-direction: column; /* Cambiar a columna en móviles */
        align-items: center;
        justify-content: center;
        margin-top: -20%;
    }

    .main-container-login {
        width: 30%; /* Aumentar el ancho en móviles */
        height: auto; /* Ajustar la altura automáticamente */
        padding: 2rem; /* Añadir algo de espacio interno */
    }

    .left-container,
    .right-container {
        width: 80%; /* Ocupar todo el ancho disponible */
        height: auto; /* Ajustar la altura automáticamente */
    }

    .logo-login {
        width: 50%; /* Aumentar el ancho del logo en móviles */
        max-width: 40%; /* Asegurar que el logo no sea demasiado grande */
        height: auto;
        margin-bottom: 20px;
        margin-top: 8rem;
    }

    .image-login {
        width: 100%; /* Aumentar el ancho de la imagen */
        max-width: 30%; /* Asegurar que la imagen no sea demasiado grande */
        height: auto;
        max-height: 40vh; /* Ajustar la altura máxima en móviles */
        border: 0.5% solid black;
        display:none;
    }

    .message-login {
        font-size: 1rem; /* Tamaño de fuente más pequeño en móviles */
    }

    .textfield-user-login {
        width: 80%; /* Ajustar el ancho del campo de texto */
        margin-bottom: 1rem; /* Añadir espacio entre campos */
    }

    .button-forgot-password,
    .help,
    .terms-links a {
        font-size: 1rem; /* Reducir el tamaño de fuente */
    }

    .message-forgot-password,
    .message-register {
        font-size: 24px; /* Tamaño de fuente más pequeño */
    }

    .instructions-forgot-password {
        font-size: 12px; /* Reducir el tamaño de fuente */
    }

    .enter-text {
        font-size: 14px; /* Tamaño de fuente más pequeño en móviles */
        margin-bottom: 20px; /* Espaciado ajustado */
    }

    .checkbox-container {
        align-items: flex-start; /* Alinear los elementos en el contenedor */
        margin-bottom: 10px;
    }

    .checkbox-label {
        margin-bottom: 5px;
    }

    .error-message {
        font-size: 12px; /* Reducir el tamaño de la fuente de errores */
    }
   
}