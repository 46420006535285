/* Header.css */

header {
    height: 7vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f9fa;
    padding: 0 2vw; /* Espacio a los lados */
    position: relative;
    z-index: 100;
}

.logo-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
}

.logo {
    width: 6vw;
    margin-top: 0vw;
}

.header_navigation {
    display: flex;
    align-items: center;
    margin-top: 0;
    flex-grow: 1;
    justify-content: flex-end; /* Alinea los elementos a la derecha */
}
.header_navigation_left {
    display: flex;
    align-items: center;
    margin-top: 0;
    flex-grow: 1;
    justify-content: flex-start; /* Alinea los elementos a la derecha */
}

.navlink {
    font-size: 1em;
    margin-left: 2vw; /* Espacio entre el logo y el primer ícono */
    border: none;
    background-color: transparent;
    padding: 0;
    display: flex;
    flex-direction: column; /* Organiza el icono y el texto en columna */
    align-items: center;
    cursor: pointer;
    outline: none; /* Elimina cualquier borde de enfoque por defecto */
}

.navlink:hover {
    background-color: transparent; /* Asegura que no haya ningún cambio en el fondo del botón */
}

.navlink-text {
    display: block;
    text-align: center;
    font-size: 0.8vw; 
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: #5A5A5A;
    margin-top: 0.3vw; /* Espacio entre el icono y el texto */
}

.icon-container {
    display: flex;
    flex-direction: column; /* Organiza icono y texto verticalmente */
    align-items: center;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

li {
    display: inline;
    margin-right: 2vw; /* Espacio entre los íconos de navegación */
}

a {
    text-decoration: none;
    color: inherit; /* Hereda el color del texto */
}

.navlink_icon,
.home_icon,
.profile_icon {
    color: #5A5A5A; /* Color inicial del ícono */
    width: 1.8vw;
    height: auto;
    fill: currentColor; /* Asegura que el ícono tome el color actual */
    transition: transform 0.3s ease, fill 0.3s ease, color 0.3s ease;
}

/* Cambiar color del ícono y texto al pasar el mouse */
.navlink:hover .home_icon,
.navlink:hover .navlink-text {
    color: #e6007e; /* Cambia el color del texto y el ícono al rosado */
    fill: #e6007e; /* Asegura que el ícono use el color rosado */
}

.navlink:hover .navlink_icon {
    color: #e6007e; /* Cambia todo el ícono de Cerrar Sesión al rosado */
    fill: #e6007e; /* Asegura que todo el ícono sea rosado */
}

/* El texto no cambia al pasar el mouse */
.navlink-text:hover {
    color: #5A5A5A; /* Mantiene el color original si no es parte de un enlace */
}

.navlink button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font: inherit;
    color: inherit;
    cursor: pointer;
    outline: none; /* Elimina el borde que aparece al enfocar el botón */
}

.navlink button:hover {
    background: none; /* Asegura que no haya fondo al pasar el mouse */
    border: none; /* Elimina cualquier borde que pueda aparecer */
    color: inherit; /* Evita cualquier cambio de color en el texto */
}

.activo {
    color: #5A5A5A !important;
    fill: #5A5A5A !important;
}

.logout-modal-overlay {
    background-color: rgba(0, 0, 0, 0.5); /* Fondo negro traslúcido */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000; /* Asegura que el modal esté por encima de otros elementos */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Contenido del modal */
.logout-modal-content {
    font-family: "Montserrat", sans-serif;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    width: 500px;
    max-width: 80%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

/* Mensaje de cierre de sesión */
.logout-message {
    font-family: "Montserrat", sans-serif;
    font-size: 1.2em;
    margin-bottom: 20px;
}
/* Estilo para los botones en el modal de cierre de sesión */
.logout-modal-buttons {
    font-family: "Montserrat", sans-serif;
    display: flex;
    justify-content: center;
    gap: 20px; /* Espacio entre los botones */
}

.logout-modal-buttons .logout-button,
.logout-modal-buttons .cancel-button {
    font-family: "Montserrat", sans-serif;
    background-color: #e6007e;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
}

.logout-modal-buttons .logout-button {
    background-color: #e6007e;
}

.logout-modal-buttons .logout-button:hover {
    background-color: #e6007e;
}

.logout-modal-buttons .cancel-button {
    background-color: #5A5A5A;
}

.logout-modal-buttons .cancel-button:hover {
    background-color: #4a4a4a;
}





@media only screen and (max-width: 767px) {
    .logo {
        width: 12vw; /* Ajustar el tamaño del logo en pantallas más pequeñas */
        margin-top: 0.5rem;
    }
    header{
        height: 12vw;
    }

    .header_navigation {
        justify-content: flex-end;
        padding: 1vh 2vw;
        
    }
    .navlink {
        font-size: 1rem;
        margin-left: 1vw;
    }

    .navlink-text {
        font-size: 1.5vw;
    }

    .navlink_icon,
    .home_icon,
    .profile_icon {
        width: 3vw;
    }

    .navlink_icon, 
    .profile_icon {
        height: 2vh;
    }
}
