.profile-container {
  width: 100vw;
  min-height: 13vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f8f9fa;
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-size: 1.5rem;
}


.profile-cover {
width: 100vw;
height: auto;
max-height: 25vh; /* Unidades de altura relativas al viewport */
overflow: hidden;
}

.cover-image {
width: 100vw;
height: 150vh;
object-fit: cover; /* Asegura que la imagen cubra el contenedor */
}
.cover-image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #E6007E;
  opacity: 0.6;
  pointer-events: none;
}

.profile-info {
  position: relative;
  bottom: 1vh;
  left: 1vw;
  top:-3.5vh;
  max-width: 100vw;
  display: flex;
  align-items: center;
  color: #ffffff;
  z-index: 10;
  font-family: "Montserrat", sans-serif;
}


.profile-info .profile-details {
  position: absolute;
  z-index: 11;
  margin-top: 3vh;
  width: 100%;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  align-items: center;
  
}

.profile-info .profile-details h2 {
  text-transform: uppercase;
  letter-spacing: 2px;
  position: absolute;
  margin-top: -14vh;
  margin-left: 1rem;
  width: auto;
  font-size: 2rem;
  color: #ffffff;
}
.dato-cv {
  color: #333; /* Color original del enlace */
  text-decoration: none; /* Eliminar el subrayado si lo tiene */
  transition: color 0.3s ease; /* Suaviza el cambio de color */
}

.dato-cv:hover {
  color: #E6007E; /* Color rosado cuando se pasa el mouse */
}

.profile-career {
  position:absolute;
  font-size: 1.7rem;
  color: #E6007E;
  font-weight: bolder;
  margin-top: -8vh;
  margin-left: 1rem;
  font-family: "Montserrat", sans-serif;
}

.profile-description {
  position:absolute;
  font-size: 0.9rem;
  color: #000000;
  width: 60vw;
  margin-top: 6vh;
  margin-left: 1vw;
  text-align: justify;
  font-family: "Montserrat", sans-serif;
}

.btn-edit-profile {
  position:absolute;
  margin-left: 42vw;
  margin-bottom: -1vh;
  padding: 0.8rem 2rem;
  background-color: #eeebeb;
  color: #E6007E;
  border: 0.15vw solid #E6007E;
  border-radius: 0.2rem;
  cursor: pointer;
  text-shadow: #5A5A5A;
  font-weight: bolder;
  font-family: "Montserrat", sans-serif;
  align-self: flex-end;
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 2px;

}

.btn-edit-profile:hover {
  background-color: #E6007E;
  color: #ffffff;
}

.linea_gris {
  position: absolute;
  width: 60%;
  border-top: 0.21rem solid #eeebeb;
  margin-top: 8rem;
  margin-left: 1rem;
}

.profile-content {
  position:absolute;
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  top: -8rem;
  font-family: "Montserrat", sans-serif;
}

.profile {
  width: 50vw;
  position:absolute;
  display: flex;
  flex-direction: column;
}
.profile-form {
  position:absolute;
  align-items: left;
  width: 50rem;
  padding: 1.5rem;
  margin-bottom: 0rem;
  margin-top:12rem;
  font-family: "Montserrat", sans-serif;
}

.profile-form h3 {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1.5rem;
  color: #5A5A5A;
  text-align: left;
  margin-left: 1rem;
  font-family: "Montserrat", sans-serif;
  margin-top: 4rem;
}
.profile-form p {
  display: flex;
  font-size: 0.9rem;
  margin-bottom: 0vw;
  text-align: left;
  margin-left: 1rem;
  margin-top: 1rem;
}

.pink-text {
  margin-top: 1rem;
  color: #E6007E;
}

.profile-section {
  position:absolute;
  align-items: left;
  width: 90rem;
  font-family: "Montserrat", sans-serif;
  
}

.profile-section h3 {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #000000;
  text-align: left;
  margin-left: 1rem;
  font-family: "Montserrat", sans-serif;
}

.progress-bar {
  margin-top: 2rem;
  width: 70%;
  height: 0.4rem;
  background-color: #f0f0f0;
  border-radius: 0.5rem;
  margin-left: 1vw;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: #E6007E;
  border-radius: 0.5rem;
}

.btn-completar-formulario {
  padding: 1rem 3rem;
  background-color: #E6007E;
  color: #ffffff;
  border: none;
  border-radius: 0.15rem;
  margin-top: 1.5rem;
  margin-left: -28rem;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;

}
.btn-completar-formulario:hover{
  background-color: white;
  color: #E6007E; 
  border: 2px solid #E6007E;
}

.personal-info-card {
  position: absolute;
  top: 1vh;
  margin-left: 65vw;
  background-color: #ffffff;
  padding: 3vw;
  width: 25vw;
  /* border: 0.15vw solid #E6007E;  */
  font-family: "Montserrat", sans-serif;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.personal-info-card h3 {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1rem;
  color: #5A5A5A;
  text-align: left;
  font-weight: bolder;

}

.personal-info-card p {
  font-size: 0.9rem;
  color: #5A5A5A;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.1vw solid #5A5A5A;
  padding-bottom: 0.5vw;
  font-family: "Montserrat", sans-serif;
}

.personal-info-card p:last-child {
  border-bottom: none;
}

.personal-info-card p strong {
  font-weight: bolder;
  color: #5A5A5A;
  font-family: "Montserrat", sans-serif;
}
.personal-info-card-help p {
  font-size: 0.8rem; /* Reduce el tamaño de la letra */
  color: #E6007E; /* Color rosado */
  font-family: "Montserrat", sans-serif; /* Asegura la consistencia con la fuente usada */
  margin-top: 3rem; 
}

.rectangulo_seccion {
margin-top: 29rem;
width: 30rem;
text-align: left;
height: auto;
}

.perfil-letra {
  text-transform: uppercase;
  letter-spacing: 2px;
margin-left: 2rem;
font-family: "Montserrat", sans-serif;
color: rgb(103, 103, 103);
font-weight: bolder;
margin-top: 10vh;
}

.horizontal-bar {
display: flex;
justify-content: space-evenly; /* Espacio igual entre los botones */
align-items: center;
background-color:white;
position: relative;
margin-left: 2.28vw;
margin-top: 2vh;
margin-bottom: 3vh;
width: 60vw;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
border-bottom: 6px solid #f0f0f0; /* Línea debajo de los botones */
height: 10vh;
}

.button-inactivate,
.button-activate,
.button-inactivate-infoprofesional,
.button-activate-infoprofesional,
.button-inactivate-infoadicional,
.button-activate-infoadicional {
background-color: white;
border: none;
color: gray;
font-family: "Montserrat", sans-serif;
cursor: pointer;
font-size: 0.9rem;
text-align: center;
padding: 2vw;
transition: background-color 0.3s, color 0.3s;
}

.button-activate, .button-activate-infoprofesional, .button-activate-infoadicional {
color: #E6007E;
border-bottom: 4px solid #E6007E; /* Línea activa */
}

.button-activate:hover, 
.button-inactivate:hover, 
.button-activate-infoprofesional:hover, 
.button-inactivate-infoprofesional:hover, 
.button-activate-infoadicional:hover, 
.button-inactivate-infoadicional:hover {
background-color: #E6007E; /* Color de fondo rosado en hover */
color: white; /* Letras blancas en hover */
}
.select-bar {
display: none; /* Ocultar el selector extra */
}
.resumen{
width: 50rem;
margin-top: 43rem;
margin-left: -1.5rem;
}

/*celular*/
@media (max-width: 767px) {
.profile-container {
  font-size: 1rem;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.profile-cover {
  width: 100%;
  height: auto;
  max-height: 5rem; 
  overflow: hidden;
}

.cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}

.profile-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vh;
  margin-left: 4vw;
  width: 100%;
}

.profile-info .profile-details {
  text-align: center;
  width: 90%;
}

.profile-info .profile-details h2 {
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 0.75rem;
  margin-top: -17vh;
  margin-left: -1rem;
}

.profile-career {
  font-size: 2vw;
  margin-top: -15vh;
  margin-left: -1rem;
}

.profile-description {
  font-size: 9px;
  margin-top: -8vh;
  margin-left: -1rem;
  width: 100%;
  text-align: justify; 
}

.btn-edit-profile {
  margin-top: -14vh;
  padding: 0.3rem;
  font-size: 2vw;
  width: auto; 
  margin-right: 10vw;
  letter-spacing: 1px;
}

.linea_gris {
  width: 100%;
  margin-top: 1.5vh;
  align-items: center;
  margin-left: -0.5rem;
}

.profile-content {
  width: 100%;
}

.profile {
  width: 100%;
  padding: 1rem;
  margin-top: -1vh;
  margin-left: -2rem;
}

.profile-form {
  width: 100%;
  margin-top: 8vh;
}

.profile-form h3 {
  font-size: 12px; 
  margin-left: 1rem;
  text-align: left;
}

.profile-form p {
  font-size: 9px; 
  margin-left: 1rem;
  text-align: justify;
  width: 80%;
}

.pink-text {
  font-size: 0.4rem;
  margin-top: 1rem;
  text-align: center;
  display: none;
}

.progress-bar {
  width: 100%;
  margin-left: 1rem;
  height: 1rem;
  margin-top: 1rem;
}

.btn-completar-formulario {
  padding: 0.3rem;
  font-size: 0.6rem;
  width: 80%;
  align-items: center;
  margin-top: 1vh;
  margin-left: -3rem;
}

.personal-info-card {
  width: 100%;
  margin-top: 5vh;
  padding: 2rem;
  display: none;
}

.horizontal-bar {
  width: 104%;
  margin-top: 2vh;
  margin-left: 1.3rem;
  
}

.horizontal-bar button {
  font-size: 9px;
  padding: 1vw;
  width: 24%;
}

.rectangulo_seccion {
  margin-top: 45vh;
  width: 85%;
  margin-left: 1.2rem;
}

.perfil-letra {
  font-size: 0.7rem;
  margin-left: 1.5rem;
  margin-top: 0.5vh;
}

.resumen {
  margin-top: 62vh;
  width: 80%;
  font-size: 12px;
  text-align: justify;
  margin-left: -0.4rem;
}
}


/*aqui lo que sería para ipad*/
@media (min-width: 768px) and (max-width: 1024px) {
.profile-container {
  font-size: 1.25rem;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.profile-cover {
  width: 100%;
  height: auto;
  max-height: 10rem; 
  overflow: hidden;
}

.cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}

.profile-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
}

.profile-info .profile-details h2 {
  font-size: 1.5rem;
  margin-top: -10%;
  margin-left: 2rem;
}

.profile-career {
  font-size: 1.2rem;
  margin-top: -6%;
  margin-left: 2rem;
}

.profile-description {
  font-size: 0.8rem;
  margin-top: 3%;
  margin-left: 2rem;
  text-align: justify; 
  width: 40rem;
}

.btn-edit-profile {
  margin-top: -5rem;
  padding: 0.4rem;
  font-size: 1rem;
  width: auto; 
  margin-right: -2rem;
  letter-spacing: 1px;
}

.linea_gris {
  width: 100%;
  margin-top: 8rem;
  align-items: center;
  margin-left: 0;
}

.profile-content {
  width: 100%;
}

.profile-form {
  width: 100%;
  margin-top: 12rem;
}

.profile-form h3 {
  font-size: 1rem; 
  margin-left: 1rem;
  text-align: left;
}

.profile-form p {
  font-size: 0.8rem; 
  margin-left: 1rem;
  text-align: justify;
  width: 100%;
}

.pink-text {
  font-size: 0.6rem;
  margin-top: 1rem;
  text-align: center;
  display: none;
}

.progress-bar {
  width: 100%;
  margin-left: 1rem;
  height: 0.4rem;
  margin-top: 1rem;
}

.btn-completar-formulario {
  padding: 0.4rem;
  font-size: 0.8rem;
  width: 80%;
  align-items: center;
  margin-top: 1rem;
  margin-left: -5.5rem;
}

.personal-info-card {
  display: none; /* Ocultar la tarjeta de información personal */
}

.horizontal-bar {
  width: 100%;
  margin-top: 2rem;
  margin-left: 2rem;
}

.horizontal-bar button {
  font-size: 0.9rem;
  padding: 1rem;
  width: 100%;
}

.rectangulo_seccion {
  margin-top: 30rem;
  width: 100%;
  margin-left: 1rem;
}

.perfil-letra {
  font-size: 1rem;
  margin-left: 2rem;
  margin-top: 1rem;
}

.resumen {
  margin-top: 40rem;
  margin-left: 2rem;
  width: 100%;
}
}
