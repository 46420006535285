.container_pregunta .form-group {
    flex: 1 1 calc(50% - 2rem); /* Two items per row with spacing */
    min-width: calc(50% - 2rem); /* Ensure single items take up the same space as two would */
    max-width: calc(50% - 2rem);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
}

.form-control {
    font-family: "Montserrat", sans-serif;
    width: 80%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: auto;
    position: relative;
}

.form-control-no-respondido {
    font-family: "Montserrat", sans-serif;
    border-color: #e6007e;
}

.label-negrita {
    font-family: "Montserrat", sans-serif;
    position:relative;
    font-weight: bold;
    font-size: 100%;
    text-align: left;
    display: block; 
    color: #5A5A5A;
    margin-bottom: 0.5rem;
}

.label-gris {
    font-family: "Montserrat", sans-serif;
    color: #777;
    font-size: 85%;
    position: relative;
    text-align: left;
    width: 80%;
    display: block; 
    margin-bottom: 0.5rem;
}

.label-roja {
    font-family: "Montserrat", sans-serif;
    color: #ff0000;
}

.botones {
    display: flex;
    justify-content: space-between;
}

button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}


.image-city {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1; 
  }
  
  .fondo_rosado {
    background-color: #e6007e; /* Rosado con opacidad */
    opacity: 0.3;
    object-fit: cover;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2;
  }
  .container-preguntas {
    min-height: 75vh; /* Increase minimum height for more space */
    height: auto;
    max-height: none;
    width: 80vw;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    position: relative;
    z-index: 4;
    background-color: #ffffff;
    margin-top: 4%;
    margin-left: 10%;
    border-radius: 10px;
    margin-bottom: 4vh;

  } 

.container_pregunta {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2rem; 
    align-items: flex-start; 
    padding: 2rem;
    width: 80vw;
    height: auto;
    min-height: 55vh;
    margin-top: 4%;
    margin-left: 1%;
    margin-bottom: 5%;
}


  .fondo_gris {
    background-color: #5A5A5A; 
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .fondo_blanco {
    background-color: #ffffff; 
    opacity: 0.3;
    position: absolute;
    top: 35%;
    left: 0;
    width: 100%;
    height: 45%;
    z-index: 3;
    display: none;
  }

  .container-perfil {
    display: flex;
    height: 65vh;
    width: 20vw;
    align-items: center;
    justify-content: space-around;
    padding: 20px;
    position: relative;
    z-index: 4;
    background-color: #ffffff;
    margin-top: -43.5%;
    margin-left: 4%;
    border-radius: 10px;
  }

.horizontal-bar-form {
    justify-content: left;
    background-color: transparent;
    position: absolute;
    display: block;
    width: 98vw;
    margin-left: -9vw;
    margin-top: 0vh;
    z-index: 100;
}

.button-inactivate-form {
    background-color: white;
    border: 4px solid #F5F5F5;
    border-top:white;
    border-left: white;
    border-right: white;
    color: gray;
    width: 15vw;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
    border-radius: 0;
    font-weight: 400;
    font-size: 0.9em;
    text-align: left;
}

.button-activate-form {
    background-color: white;
    border: 4px solid #E6007E;
    border-top:white;
    border-left: white;
    border-right: white;
    border-radius: 0;
    width: 15vw;
    color: #E6007E;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
    font-weight: 400;
    font-size: 0.9em;
    transition: background-color 0.3s, color 0.3s;
    text-align: left;

}
.button-activate-form:hover {
    background-color: #E6007E !important; 
    color: white !important; 
}


.button-inactivate-infoprofesional-form {
    background-color: white;
    border: 4px solid #F5F5F5;
    border-top:white;
    border-left: white;
    border-right: white;
    color: #808080;
    width: 15vw;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
    border-radius: 0;
    font-weight: 400;
    font-size: 0.9em;
    text-align: left;   
    transition: background-color 0.3s, color 0.3s;

}
.button-inactivate-form:hover {
    background-color: #E6007E !important; 
    color: white !important; 
}
.button-inactivate-infoprofesional-form:hover {
    background-color: #E6007E !important; /* Color de fondo rosado en hover */
    color: white !important; /* Letras blancas en hover */
}
.button-inactivate-infoadicional-form:hover {
    background-color: #E6007E !important; /* Color de fondo rosado en hover */
    color: white !important; /* Letras blancas en hover */
}

.button-activate-infoprofesional-form {
    text-align: left;
    background-color: white;
    border: 4px solid #E6007E;
    border-top:white;
    border-left: white;
    border-right: white;
    border-radius: 0;
    width: 15vw;
    color: #E6007E;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
    font-weight: 400;
    font-size: 0.9em;
    transition: background-color 0.3s, color 0.3s;
}
.button-activate-infoprofesional-form:hover {
    background-color: #E6007E; /* Color de fondo rosado en hover */
    color: white; /* Letras blancas en hover */
}

.button-inactivate-infoadicional-form {
    text-align: left;
    background-color: white;
    border: 4px solid #F5F5F5;
    border-top:white;
    border-left: white;
    border-right: white;
    color: gray;
    width: 15vw;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
    border-radius: 0;
    font-weight: 400;
    font-size: 0.9em;
}

.button-activate-infoadicional-form {
    text-align: left;
    background-color: white;
    border: 4px solid #E6007E;
    border-top:white;
    border-left: white;
    border-right: white;
    border-radius: 0;
    width: 15vw;
    color: #E6007E;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
    font-weight: 400;
    font-size: 0.9em;
    transition: background-color 0.3s, color 0.3s;
}
.button-activate-infoadicional-form:hover {
    background-color: #E6007E; /* Color de fondo rosado en hover */
    color: white; /* Letras blancas en hover */
}

.telephony-fields {
    display: flex;
    align-items: center;
  }
  
.telephony-fields input[type="tel"] {
width: 130px; 
}
.codigo-input {
    width: 50px; 
    margin-right: 10px; 
}

.nueva-clase {
display: flex;
align-items: center;
}

.input-multi-select {
    font-family: 'Montserrat';
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    margin-top: 2vw;
    width: 80%;
    position: relative; 
}

.form-control-brief {
    width: 75vw;
    height: 13.8vh;
    border-radius: 7px;
    border: 1px solid #D9D9D9;
    margin-bottom: 1vh;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.7vh;
    line-height: 1.22;
    font-weight: 500;
    resize: none;  
    position: relative;
    padding: 12px;
    
}
.form-control-brief-no-respondido {
    width: 75vw;
    height: 13.8vh;
    border-radius: 7px;
    border: 1px solid #fc047c;
    margin-bottom: 1vh;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5vh;
    line-height: 1.22;
    font-weight: 500;
    resize: none;  
}
.button-cv {
    position:absolute;
    padding: 1.1vh 1.3vw;
    background-color: #fc047c;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 11px;
    margin-left: 25vw;
    margin-top: 7%;
}

.button-cv:hover{
    background-color: white;
    color: #E6007E;
    border: 1px solid #E6007E;

}
/* Botón personalizado para la subida de archivos */
.custom-file-label {
    display: inline-block;
    padding: 10px 20px;
    background-color: white;
    color: #E6007E;
    border: 1px solid #E6007E;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 14px;
    margin-top: 5vh;
    border-radius: 5px;
}

.custom-file-label:hover {
    background-color: #E6007E;
    color: white;
}

/* Texto para mostrar el nombre del archivo seleccionado */
.file-chosen {
    margin-left: 1rem;
    font-family: "Montserrat", sans-serif;
    color: #5A5A5A;
    font-size: 14px;
    font-weight: 400;
    margin-top: 10vh;
}

/* Contenedor para el campo de archivo */
.cv-container {
    margin-top: 30vh;
    margin-bottom: 2rem;
    margin-left: -90vw;
}

.cv{
    width: 100vw; 
    margin-top: 4vh;
    position:absolute;
}
.label-cv{
    font-family: "Montserrat", sans-serif;
    position:absolute;
    font-weight: bold;
    font-size: 100%;
    margin-left: 0%;
}

.close-button {
    position: absolute;
    top: -1vh;
    left: 86.5vw;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    z-index: 10;
    color: grey;
}

.close-button:hover {
    background: none;
    color: #e6007e;
}
.button-container-form {
    display: flex;
    width: 10vw;
    align-items: center;
    margin-top: -5vh;
    margin-left: 55vw;
    gap: 9rem;
    margin-bottom: 1vh;
  }


@media only screen and (max-width: 896px) {
    
    .form-group{
        font-size: 0.7rem;
    }
    .form-control{
        font-size: 0.7rem;
        width: 65vw;
    }
    .form-control-no-respondido{
        font-size: 0.7rem;
        width: 67vw;
    }
    .fondo_blanco{
        display: none;
    }
    .container_pregunta .form-group {
        flex: 1 1 100%; /* On small screens, fields take up 100% of the width */
        min-width: 100%; /* Ensures the fields are full width */
        max-width: 100%; /* Ensures the fields don't exceed full width */
    }

.horizontal-bar-form{
    width: 95%;
    margin-top: 1%;
    margin-left: -4%;
}

.container_pregunta {
    display: flex;
    height: 50vh;
    width: 70vw;
    align-items: center;
    padding: 20px;
    position: absolute;
    z-index: 5; 
    background-color: transparent;
    margin-top: 17%;
    margin-left: -3%;
    flex-direction: row;
    flex-wrap: wrap;    
    overflow-y: auto;
    gap: 3rem;  
  } 

  .container_pregunta::-webkit-scrollbar {
    width: 2px; /* Ancho del scroll */
  }
  
  .container_pregunta::-webkit-scrollbar-thumb {
    background-color: #e6007e; /* Color de la barra de scroll */
    border-radius: 10px;
  }
  
  .container_pregunta::-webkit-scrollbar-track {
    background-color: transparent; /* Fondo de la barra de scroll */
  }
.container-preguntas {
    margin-left: 1.4rem;
    width: 80%;
    height: 90vh;
    position:relative;
    margin-top: 3rem;
}
.container-editar{
    margin-left: 1rem;
}
.input-multi-select{
    height: 80%;
    width: 100%;
}
.button-inactivate-form,
    .button-activate-form,
    .button-inactivate-infoprofesional-form,
    .button-activate-infoprofesional-form,
    .button-inactivate-infoadicional-form,
    .button-activate-infoadicional-form {
        width: 20%; /* Ajustamos el ancho para pantallas móviles */
        font-size: 0.45rem; /* Reducción del tamaño de fuente para móviles */
        text-align: left;
        padding-left: 0.3rem;
    }
.container-perfil{
    display: none;
}

.posicion-botones{
    margin-top: 220%;
    margin-left: -2rem;
    position: relative;

}
.button-container-form {
    flex-direction: column; /* En pantallas pequeñas, los botones se apilarán */
    align-items: center;
    gap: 5rem;
    margin-top: 85vh;
    margin-left: 34vw;
    height: 1vh;
  }

  .button-container-form button {
    margin: 1px 0; /* Espaciado entre los botones en pantallas pequeñas */
  }
  .form-control-brief {
    width: 65vw;
   
}
.form-control-brief-no-respondido {
    width: 65vw;
}

.cv-container{
    margin-top: -3vh;
    margin-bottom: 2rem;
    margin-left: 5vw;
    display: flex;
    flex-direction: column;
    
}
.custom-file-label {
    display: inline-block;
    padding: 10px 20px;
    background-color: white;
    color: #E6007E;
    border: 1px solid #E6007E;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    font-size: 8px;
    border-radius: 5px;
}

.custom-file-label:hover {
    background-color: #E6007E;
    color: white;
}

/* Texto para mostrar el nombre del archivo seleccionado */
.file-chosen {
    margin-left: 1rem;
    font-family: "Montserrat", sans-serif;
    color: #5A5A5A;
    font-size: 8px;
    font-weight: 300;
    margin-top: 3vh;
}

.button-cv {
    padding: 1vh 6vw;
    margin-left: 25vw;
    margin-top: 15vh;
    font-size: 8px;
    font-weight: 300;
}

.image-city{
    width: 100vw;
    height: 100vh; 
    object-fit: cover;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
}
.close-button {
    margin-top: -7%;
    margin-left: -11%;
}


}